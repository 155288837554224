import { gql } from '@apollo/client';

export const GET_MEDIAS = gql`
	query getMedias(
		$libraryId: String!
		$folderId: String!
		$cursor: String
		$size: Int
		$filters: [MediaFilter]
		$sort: MediaSortField
		$order: MediaSortOrder
	) {
		searchMedias(libraryId: $libraryId, folderId: $folderId, cursor: $cursor, size: $size, filters: $filters, sort: $sort, order: $order) {
			totalCount
			cursor
			searchId
			medias {
				id
				mediaType
				cloudinaryId
				cloudinaryVersion
				globalDescription
				name
				createdAt
				path
				width
				height
				... on Video {
					duration
				}
				isSample
			}
		}
	}
`;

export const GET_UPLOADED_MEDIAS = gql`
	query getUploadedMedias(
		$libraryId: String!
		$folderId: String!
		$cursor: String
		$size: Int
		$filters: [MediaFilter]
		$sort: MediaSortField
		$order: MediaSortOrder
	) {
		searchMedias(libraryId: $libraryId, folderId: $folderId, cursor: $cursor, size: $size, filters: $filters, sort: $sort, order: $order) {
			totalCount
			searchId
			medias {
				id
				mediaType
				cloudinaryId
				cloudinaryVersion
				globalDescription
				name
				createdAt
				path
				width
				height
				... on Video {
					duration
				}
				isSample
			}
		}
	}
`;

export const GET_MEDIAS_COUNTER = gql`
	query getMediasCounter($libraryId: String!, $folderId: String!) {
		searchMedias(libraryId: $libraryId, folderId: $folderId) @client {
			totalCount
		}
	}
`;

export const GET_SEARCH_CONTEXT = gql`
	query getSearchContext {
		library @client {
			currentLibraryId
			currentFolderId
			search {
				selectedFilters {
					type
					box
					byValue
				}
				selectedBoxes {
					key
					name
					toggleSelected
				}
			}
		}
	}
`;

export const GET_SELECTED_FILTER_VALUES = gql`
	query getSelectedFilterValues($filterType: String!) {
		librarySelectedFilterValues(filterType: $filterType) @client {
			key
			id
			name
		}
	}
`;

export const GET_BOX_SELECTED_FILTER_VALUES = gql`
	query getBoxSelectedFilterValues($filterBox: String!) {
		libraryBoxSelectedFilterValues(filterBox: $filterBox) @client {
			key
			id
			name
		}
	}
`;

export const GET_ALL_SELECTED_FILTER_VALUES = gql`
	query getAllSelectedFilterValues {
		libraryAllSelectedFilterValues @client
		library @client {
			search {
				selectedFilters {
					type
					box
					byValue
				}
			}
		}
	}
`;

export const GET_ACTIONED_FILTER = gql`
	query getActionedFilter {
		library @client {
			search {
				actionedFilter {
					type
					box
				}
			}
		}
	}
`;

export const GET_FILTER_LOADING = gql`
	query getFilterLoading {
		library @client {
			search {
				filterLoading
			}
		}
	}
`;

export const GET_MEDIAS_AGGREGATED = gql`
	query getAggregationQuery($libraryId: String!, $folderId: String, $tags: [String]!, $filters: [MediaFilter]) {
		aggregateMedias(libraryId: $libraryId, folderId: $folderId, tags: $tags, filters: $filters) {
			type
			byValue {
				id
				key
				count
				type
				name
			}
		}
	}
`;
