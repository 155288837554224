import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

import { borderGreyColorStronger, launchmetricsGrey } from 'Styles/theme';

export const MOBILE_HEADER_BREAKPOINT = '767px';
export const useStyles = makeStyles(theme => ({
	layoutRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	appBar: {
		backgroundColor: launchmetricsGrey,
		boxShadow: 'none'
	},
	appBarStatic: {
		transform: 'initial',
		position: 'initial'
	},
	appBarAppSwitcherBox: {
		zIndex: 1
	},
	header: {
		backgroundColor: '#FFF',
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		minHeight: 56,
		padding: '8px 50px'
	},
	headerPadding: {
		padding: '0px 50px',
		[theme.breakpoints.down('md')]: {
			padding: '0 10px'
		}
	},
	headerButtonsContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	headerButtonsContainerResponsive: {
		flexBasis: '100%',
		justifyContent: 'center',
		margin: '10px 0'
	},
	headerUserActionsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& > *': {
			position: 'relative',
			padding: '0 20px',
			'&:not(:last-child):after': {
				content: '""',
				width: 1,
				height: 1,
				position: 'absolute',
				right: 0,
				top: '50%',
				transform: 'scaleY(15)',
				backgroundColor: grey[500]
			},
			'&:last-child': {
				paddingRight: 0
			}
		}
	},
	headerUserMenu: {
		display: 'flex',
		alignItems: 'center'
	},
	progress: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		marginTop: -13,
		marginLeft: -13
	},
	tenantLogoLabel: {
		display: 'inline-block',
		paddingRight: 10
	},
	tenantLogoWrapper: {},
	tenantLogoUploadLabel: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 30,
		height: 30,
		border: `1px solid ${grey[300]}`,
		borderRadius: '50%',
		padding: 3,
		position: 'relative',
		marginRight: 20,
		overflow: 'hidden',
		'&:after': {
			content: '""',
			width: 1,
			height: 1,
			position: 'absolute',
			right: -20,
			top: '50%',
			transform: 'scaleY(15)',
			backgroundColor: grey[500]
		}
	},
	tenantLogo: {
		width: '100%'
	},
	tenantLogoFade: {
		opacity: '.5'
	},
	updateLogoText: {
		position: 'absolute',
		top: 0,
		left: 0,
		textAlign: 'center',
		width: '100%',
		height: '100%',
		background: 'rgba(0,0,0,.7)',
		color: '#fff',
		lineHeight: '36px',
		fontSize: 10,
		opacity: 0,
		transition: 'all .2s',
		'&:hover': {
			opacity: 1
		}
	},
	headerUserMenuText: {
		color: grey[700],
		marginLeft: 15
	},
	headerTenantMenu: {
		display: 'flex',
		alignItems: 'center'
	},
	tabsSectionContainer: {
		display: 'flex',
		alignContent: 'center'
	},
	tabs: {
		color: '#FFF'
	},
	tabsDivider: {
		backgroundColor: 'white',
		height: 15,
		alignSelf: 'center',
		margin: '0 20px'
	},
	tab: {
		textTransform: 'none',
		minWidth: '8.31rem',
		fontSize: 17
	},
	buttonTab: {
		color: 'white',
		minWidth: '8.31rem',
		fontSize: 17,
		paddingRight: 40,
		paddingLeft: 40
	},
	tabMenuPopper: {
		marginTop: 3,
		backgroundColor: 'white',
		boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.05)',
		border: `1px solid ${borderGreyColorStronger}`,
		borderRadius: '2px 2px 4px 4px'
	},
	tabMenuContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > :first-child': {
			paddingTop: 8
		},
		'& > :last-child': {
			paddingBottom: 8
		}
	},
	tabMenuItem: {
		fontSize: 12,
		fontWeight: 500,
		justifyContent: 'flex-start',
		padding: '8px 24px'
	},

	disabled: {
		cursor: 'not-allowed'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '100%',
		width: '100%',
		backgroundColor: '#FFF',
		padding: 0
	}
}));
