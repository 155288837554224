import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import { nanoid } from 'nanoid';

import client, { getCache } from 'Apollo';
import StateManager from 'Apollo/StateManager';
import { GET_UPLOADED_MEDIAS } from 'Apollo/Store/Library/Search/queries';
import { AUTOTAG_MEDIA, REMOVE_MEDIA, UPDATE_MEDIA, UPDATE_NEW_MEDIA } from 'Apollo/Store/Media/queries';
import {
	LibraryUploadStatus,
	MediaStatus,
	MediaType,
	LibraryResultsUi,
	GetMediasQueryVariables,
	MutationUpdateMediaArgs,
	UpdateMediaMutation,
	GetUploadedMediasQueryVariables,
	GetUploadedMediasQuery,
	CreateMediaResponse
} from 'generated/graphql';

import CloudinaryApi from 'lib/CloudinaryApi';

const cache = getCache();
const stateManagerPath = 'library.results';
const stateManager = new StateManager(stateManagerPath);

export type ItemsMediaParams = {
	[key: string]: CreateMediaResponse;
};

export type UploadItemsParams = {
	libraryId: string;
	mediaParams: ItemsMediaParams;
	path: string;
	pathIds: string;
	files: FileList;
	retry: boolean;
	errorId?: number;
	location?: string;
	seasonYear?: number;
	showDate?: string;
	autotag: boolean;
};

export const retryUploadItems = async (path: string) => {
	let state = stateManager.get();
	let currentItem = find(state.uploadingQueue, { path });
	const processToRetry = filter(currentItem.processes, { error: true });
	processToRetry.forEach(proc => {
		uploadItems({
			mediaParams: proc.mediaParams,
			path: currentItem.path,
			files: proc.pendingFiles,
			retry: true,
			errorId: proc.id,
			location: proc.tags.location[0],
			seasonYear: currentItem.seasonYear,
			libraryId: currentItem.libraryId,
			pathIds: currentItem.pathIds,
			showDate: currentItem.showDate,
			autotag: currentItem.autotag
		});
	});
};
export const uploadItems = async ({
	mediaParams,
	path,
	files,
	retry,
	errorId,
	location,
	libraryId,
	pathIds,
	seasonYear,
	showDate,
	autotag
}: UploadItemsParams) => {
	const itemId = addItemToUploadingQueue({ mediaParams, path, files, retry, errorId, location, libraryId, pathIds, seasonYear, showDate, autotag });
	closeUploadDrawer();
	const MAX_FILE_SIZE = 100_000_000;
	const MAX_CHUNK_SIZE = 20_000_000;

	for (let index = 0; index < files.length; index++) {
		const UPLOAD_ID = nanoid(30);
		const file = files[index];
		const fileType = file.type.replace(/\/(.*)/, '');
		const {
			media: { name: mediaName, id: mediaId, mediaType },
			publicId,
			signedUrl
		} = mediaParams[file.name];
		const chunks = [];
		let public_id;
		let resource_type;

		if (file.size > MAX_FILE_SIZE) {
			const parts = Math.ceil(file.size / MAX_CHUNK_SIZE);
			for (let i = 0; i < parts; i++) {
				const start = i * MAX_CHUNK_SIZE;
				const end = (i + 1) * MAX_CHUNK_SIZE;
				const endOffset = i === parts - 1 ? file.size - 1 : end - 1;

				chunks.push({
					start,
					end,
					blob: file.slice(start, end),
					rangeHeader: `bytes ${start}-${endOffset}/${file.size}`
				});
			}
		} else {
			chunks.push({ start: 0, end: file.size, blob: file });
		}

		const uploads = chunks.map(async (chunk, i) => {
			const headers = {
				'Content-Type': 'multipart/form-data'
			};
			if (chunks.length > 1) {
				headers['Content-Range'] = chunk.rangeHeader;
				headers['X-Unique-Upload-Id'] = UPLOAD_ID;
			}

			const api = new CloudinaryApi({ headers });
			const formData = new FormData();
			formData.append('public_id', publicId);
			formData.append('file', chunk.blob);
			try {
				let res;
				if (mediaType !== MediaType.Pdf) {
					res = await api.post(`${process.env.REACT_APP_CLOUDINARY_APP}/${fileType}/upload?${signedUrl}`, formData);
				} else {
					res = await api.post(`${process.env.REACT_APP_CLOUDINARY_APP}/image/upload?${signedUrl}`, formData);
				}
				if (!res) {
					client.mutate({
						mutation: UPDATE_MEDIA,
						variables: {
							mediaParams: {
								id: mediaId,
								status: MediaStatus.UploadError
							}
						}
					});
					throw Error('Not received response from cloudinary when uploading media from library/showroom');
				}

				if (chunks.length > 1 && !res.data.done) return;

				public_id = res.data.public_id;
				resource_type = res.data.resource_type;

				const updateFields: Partial<MutationUpdateMediaArgs['mediaParams']> = {
					status: MediaStatus.Ready,
					name: mediaName,
					extension: res.data.format,
					size: res.data.bytes,
					cloudinaryId: res.data.public_id,
					cloudinaryVersion: res.data.version,
					duration: res.data.duration,
					width: res.data.width,
					height: res.data.height,
					seasonYear,
					showDate,
					mediaType: res.data.format === 'pdf' ? MediaType.Pdf : res.data.resource_type === 'video' ? MediaType.Video : MediaType.Image
				};

				if (location) updateFields.tagIds = [location];
				const { overwritten } = res.data;
				if (overwritten) {
					// If the media is already present in cloudinary we:
					// 1) Delete the media we were trying to create
					// 2) Update the db media linked to the cloudinary asset. In case it doesn't exists in our db, we create it with an upsert operation
					try {
						await client.mutate({
							mutation: REMOVE_MEDIA,
							variables: {
								id: mediaId
							}
						});
					} catch (err) {
						const removeMediaError = err.graphQLErrors?.find(error => error.path.includes('removeMedia'));
						if (removeMediaError?.extensions?.code === 'NOT_FOUND') {
							// The image does not exist and can't be overriden, we should stop the process for it.
							return;
						}
						throw err;
					}
					const updateRes = await client.mutate<UpdateMediaMutation>({
						mutation: UPDATE_MEDIA,
						variables: {
							mediaParams: {
								id: public_id,
								libraryId,
								path: pathIds,
								...updateFields
							},
							upsert: true
						}
					});
					if (autotag && updateRes.data.updateMedia.__typename === 'Image') {
						client.mutate({
							mutation: AUTOTAG_MEDIA,
							variables: {
								id: updateRes.data.updateMedia.id,
								type: 'framing',
								overwrite: false
							}
						});
					}
				} else {
					await client.mutate({
						mutation: UPDATE_NEW_MEDIA,
						variables: {
							mediaParams: {
								id: mediaId,
								...updateFields
							}
						}
					});
				}
			} catch (err) {
				client.mutate({
					mutation: UPDATE_MEDIA,
					variables: {
						mediaParams: {
							id: mediaId,
							status: MediaStatus.UploadError
						}
					}
				});
				throw Error(err);
			}
		});

		try {
			await Promise.all(uploads);
			const state = stateManager.get();
			const uploadingQueue = cloneDeep(state.uploadingQueue);
			const currentItem = find(uploadingQueue, { path });
			const currentProcess = find(currentItem.processes, { id: itemId });
			currentProcess.processedFiles += 1;
			currentItem.uploadedFiles.push({ publicId: public_id, mediaType: resource_type, mediaId });
			stateManager.update({
				uploadingQueue: uploadingQueue
			});
		} catch (error) {
			const state = stateManager.get();
			const uploadingQueue = cloneDeep(state.uploadingQueue);
			const currentItem = find(uploadingQueue, { path });
			const currentProcess = find(currentItem.processes, { id: itemId });
			currentProcess.error = true;
			currentProcess.pendingFiles = Array.from(files).splice(index, files.length);
			currentProcess.params = mediaParams;

			stateManager.update({
				uploadingQueue: uploadingQueue
			});
			break;
		}
	}

	if (isUploadItemCompleted(path)) uploadItemStatus(path, LibraryUploadStatus.Completed);
};

export const isUploadItemCompleted = (path: string) => {
	const state = stateManager.get();
	const currentItem = find(state.uploadingQueue, { path });
	return isEmpty(filter(currentItem.processes, proc => proc.images + proc.videos > proc.processedFiles));
};

export const uploadItemStatus = (path: string, status: LibraryUploadStatus) => {
	const state = stateManager.get();
	let uploadingQueue = cloneDeep(state.uploadingQueue);
	const currentItem = find(uploadingQueue, { path });
	currentItem.status = status;
	stateManager.update({ uploadingQueue });
};

export const setItemAsFetched = (path: string) => {
	const state = stateManager.get();
	let uploadingQueue = cloneDeep(state.uploadingQueue);
	const currentItem = find(uploadingQueue, { path });
	currentItem.status = LibraryUploadStatus.Fetched;
	stateManager.update({ uploadingQueue });
};

export const removeItemFromUploadingQueue = (path: string) => {
	const state = stateManager.get();
	let uploadingQueue = cloneDeep(state.uploadingQueue);
	const currentItem = find(uploadingQueue, { path });

	const isAnyProcessRunning = !currentItem ? [] : filter(currentItem.processes, proc => proc.images + proc.videos > proc.processedFiles);
	// If there is no more processes, remove current upload item
	if (isEmpty(isAnyProcessRunning)) {
		uploadingQueue = filter(uploadingQueue, uploadItem => {
			return uploadItem.path !== path;
		});
	}
	stateManager.update({
		uploadingQueue,
		shouldDisplayReloadButton: true
	});
};

export const showReloadButton = () => {
	stateManager.update({
		shouldDisplayReloadButton: true
	});
};

export const hideReloadButton = () => {
	stateManager.update({
		shouldDisplayReloadButton: false
	});
};

export const addItemToUploadingQueue = ({
	mediaParams,
	path,
	files,
	retry,
	errorId,
	location,
	libraryId,
	pathIds,
	seasonYear,
	showDate,
	autotag
}: UploadItemsParams): number => {
	const state = stateManager.get();
	const copyOfCurrentState = cloneDeep(state.uploadingQueue);
	const currentItem = find(copyOfCurrentState, { path });
	let id = 0;

	if (currentItem) {
		const currentProcess = find(currentItem.processes, { id: errorId });
		if (retry) {
			currentProcess.error = false;
			id = errorId;
		} else {
			id = currentItem.processes[currentItem.processes.length - 1].id + 1;
			currentItem.processes.push({
				mediaParams,
				images: filter(files, file => file.type.match('image')).length,
				videos: filter(files, file => file.type.match('video')).length,
				processedFiles: 0,
				error: false,
				pendingFiles: [],
				id,
				tags: { location: [location] },
				seasonYear,
				showDate
			});
		}
		currentItem.status = LibraryUploadStatus.Uploading;
		stateManager.update({
			uploadingQueue: copyOfCurrentState
		});
	} else {
		stateManager.update({
			uploadingQueue: [
				...copyOfCurrentState,
				{
					path,
					libraryId,
					pathIds,
					uploadedFiles: [],
					status: LibraryUploadStatus.Uploading,
					autotag,
					processes: [
						{
							mediaParams,
							images: filter(files, file => file.type.match('image')).length,
							videos: filter(files, file => file.type.match('video')).length,
							processedFiles: 0,
							error: false,
							pendingFiles: [],
							id: 0,
							tags: { location: [location] },
							seasonYear,
							showDate
						}
					]
				}
			]
		});
	}

	return id;
};

export const openUploadDrawer = () => {
	stateManager.update({
		isUploadDrawerOpen: true
	});
};

export const closeUploadDrawer = () => {
	stateManager.update({
		isUploadDrawerOpen: false
	});
};

export const openRequestSpotlightDrawer = () => {
	stateManager.update({
		isRequestSpotlightDrawerOpen: true
	});
};

export const closeRequestSpotlightDrawer = () => {
	stateManager.update({
		isRequestSpotlightDrawerOpen: false
	});
};

export const toggleMedia = (id: string, type: string) => {
	const { selectedMedias }: LibraryResultsUi = stateManager.get();

	const selected = find(selectedMedias, { id });
	stateManager.update({
		selectedMedias: !!selected ? without(selectedMedias, selected) : [...selectedMedias, { id, type }]
	});
};

export const selectMedias = (medias: { id: string; type: string }[]) => {
	stateManager.update({
		selectedMedias: medias
	});
};

export const unselectAllMedias = () => {
	stateManager.update({
		selectedMedias: []
	});
};

export const toggleBigPreview = () => {
	const state: LibraryResultsUi = stateManager.get();
	const currentStatus = state.bigPreview;
	stateManager.update({ bigPreview: !currentStatus });
};

export const clearResults = (libraryId: string, folderId: string) => {
	cache.modify({
		fields: {
			searchMedias: (prevData, { DELETE, storeFieldName, fieldName }) => {
				const params: GetMediasQueryVariables = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
				if (libraryId === params.libraryId && folderId === params.folderId) return DELETE;
				return prevData;
			}
		}
	});
};

export const writeUploadedMediasQuery = (variables: GetUploadedMediasQueryVariables, data: GetUploadedMediasQuery) => {
	cache.writeQuery({
		query: GET_UPLOADED_MEDIAS,
		variables,
		data
	});
};
