import { FieldReadFunction } from '@apollo/client';

import StateManager from 'Apollo/StateManager';
import { LibraryResultsUi } from 'generated/graphql';

export const librarySelectionModeEnabledRead: FieldReadFunction = () => {
	const stateManager = new StateManager<LibraryResultsUi>('library.results');
	const { selectedMedias } = stateManager.get();
	return selectedMedias.length > 0;
};

export const librarySelectedMediasRead: FieldReadFunction = (_, { toReference, cache }) => {
	const stateManager = new StateManager<LibraryResultsUi>('library.results');
	const { selectedMedias } = stateManager.get();
	return selectedMedias.map(selectedMedia =>
		toReference(
			cache.identify({
				id: selectedMedia.id,
				__typename: selectedMedia.type
			})
		)
	);
};
