import exif from 'exif-reader';

import { loadImage } from 'lib/imageLoader';

export type ImageSizeThreshold = {
	imageSize: number;
	cloudinarySize: number;
};

export const imageThresholds: ImageSizeThreshold[] = [
	{ imageSize: 350, cloudinarySize: 400 },
	{ imageSize: 700, cloudinarySize: 800 },
	{ imageSize: 1100, cloudinarySize: 1200 },
	{ imageSize: 1650, cloudinarySize: 1800 },
	{ imageSize: 2000, cloudinarySize: 2200 }
];

// [X, Y]: X is mobile view, Y is desktop view
export type ImageSizeValues = [number, number];
export type SizeNames = 'default';
export const imageSizes: Record<SizeNames, ImageSizeValues> = {
	default: [100, 20]
};
export const generateCustomImageSizes = (mobileSizeRatio = 1, desktopSizeRatio = 1): ImageSizeValues => [
	100 / mobileSizeRatio,
	100 / desktopSizeRatio
];

export const MAX_LOGO_RESOLUTION = 50000000;

export const verifyImageResolution = async (imageUrl: string, maxSize: number) => {
	try {
		const image = await loadImage(imageUrl);
		return image.width && image.height && image.width * image.height < maxSize;
	} catch (err) {
		return false;
	}
};

const verifyTiffResolution = async (buffer: Buffer, maxSize: number) => {
	try {
		const exifData = exif(buffer);
		const { ImageWidth, ImageLength } = exifData?.Image;

		return ImageWidth && ImageLength && ImageWidth * ImageLength < maxSize;
	} catch (err) {
		return false;
	}
};

export const isFileAcceptable = async (file: File) => {
	try {
		const sizeInMb = file.size / 1000 / 1000;
		if (file.type.match(/^video/) || file.type.match(/^application\/pdf/)) {
			return sizeInMb < 2.5 * 1024;
		} else {
			const isAcceptable =
				file.type === 'image/tiff'
					? await verifyTiffResolution(Buffer.from(await file.arrayBuffer()), MAX_LOGO_RESOLUTION)
					: await verifyImageResolution(URL.createObjectURL(file), MAX_LOGO_RESOLUTION);
			return sizeInMb < 300 && isAcceptable;
		}
	} catch (err) {
		return false;
	}
};
