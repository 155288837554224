import { gql } from '@apollo/client';

export const MediaNameFragment = gql`
	fragment MediaNameFragment on Media {
		name
		mediaType
	}
`;

export const MediaFragment = gql`
	fragment MediaFragment on Media {
		id
		name
		cloudinaryId
		cloudinaryVersion
		globalDescription
		isSample
		mediaType
		path
		createdAt
		width
		height
		... on Video {
			duration
		}
	}
`;

export const MEDIA_TAG_FRAGMENT = gql`
	fragment MediaTagFragment on Tag {
		id
		name
		key
		type
		isCustom
		canBeAdded
	}
`;

export const MEDIA_AI_TAG_FRAGMENT = gql`
	fragment MediaAITagFragment on MediaAITag {
		tag {
			...MediaTagFragment
			isLeaf
		}
		path
		probability
	}
	${MEDIA_TAG_FRAGMENT}
`;

export const GET_MEDIAS_BY_IDS = gql`
	query getMediasByIds($mediaIds: [String]!) {
		mediasByIds(mediaIds: $mediaIds) {
			id
			cloudinaryId
			cloudinaryVersion
			mediaType
			width
			height
			name
			createdAt
			isSample
		}
	}
`;

export const CREATE_MEDIA = gql`
	mutation createMedia(
		$folder: String!
		$libraryId: String!
		$publicId: String
		$path: String!
		$uniqueFileName: Boolean
		$mediaName: String
		$location: String
		$mediaType: MediaType
		$showDate: String
		$pressKit: Boolean
	) {
		createMedia(
			libraryId: $libraryId
			folder: $folder
			publicId: $publicId
			path: $path
			uniqueFileName: $uniqueFileName
			mediaName: $mediaName
			location: $location
			mediaType: $mediaType
			showDate: $showDate
			pressKit: $pressKit
		) {
			signedUrl
			media {
				id
				name
				mediaType
			}
			publicId
		}
	}
`;

export const CREATE_MEDIAS = gql`
	mutation createMedias($createMediaParams: [CreateMediaParams]!) {
		createMedias(createMediaParams: $createMediaParams) {
			signedUrl
			media {
				id
				name
				mediaType
			}
			publicId
			failed
		}
	}
`;

export const UPDATE_NEW_MEDIA = gql`
	mutation updateNewMedia($mediaParams: MediaParams, $upsert: Boolean) {
		updateNewMedia(mediaParams: $mediaParams, upsert: $upsert) {
			id
			name
			status
		}
	}
`;

export const UPDATE_MEDIA = gql`
	mutation updateMedia($mediaParams: MediaParams, $upsert: Boolean) {
		updateMedia(mediaParams: $mediaParams, upsert: $upsert) {
			id
			name
			status
		}
	}
`;

export const AUTOTAG_MEDIA = gql`
	mutation autoTagMedia($id: String!, $type: String!, $overwrite: Boolean) {
		autoTagMedia(id: $id, type: $type, overwrite: $overwrite) {
			message
		}
	}
`;

export const UPDATE_MEDIA_DETAILS = gql`
	mutation updateMediaDetails($mediaDetails: UpdateMediaTagsParams) {
		updateMediaDetails(mediaDetails: $mediaDetails) {
			id
			name
			tags {
				id
				name
				key
				type
				isCustom
			}
			tagRelationships {
				id
				target {
					...MediaTagFragment
				}
				relations {
					...MediaTagFragment
					colorCode
				}
				isSampleLinked
			}
		}
	}
	${MEDIA_TAG_FRAGMENT}
`;

export const UPDATE_MEDIA_DETAILS_ONE_BY_ONE = gql`
	mutation updateMediaDetailsOneByOne($mediaDetails: [UpdateMediaTagsParams]) {
		updateMediaDetailsOneByOne(mediaDetails: $mediaDetails) {
			id
			name
			tags {
				id
				name
				key
				type
				isCustom
			}
			tagRelationships {
				id
				target {
					...MediaTagFragment
				}
				relations {
					...MediaTagFragment
					colorCode
				}
				isSampleLinked
			}
		}
	}
	${MEDIA_TAG_FRAGMENT}
`;

export const DELETE_MEDIAS = gql`
	mutation deleteMedias($imageIds: [String]!, $videoIds: [String]!, $pdfIds: [String]) {
		setMediasAsDeleted(imageIds: $imageIds, videoIds: $videoIds, pdfIds: $pdfIds) {
			status
			deletedMedias {
				id
				status
			}
		}
	}
`;

export const REMOVE_MEDIA = gql`
	mutation removeMedia($id: String!) {
		removeMedia(id: $id) {
			status
			removed
		}
	}
`;

export const REMOVE_MEDIAS = gql`
	mutation removeMedias($ids: [String]!) {
		removeMedias(ids: $ids) {
			status
			removed
		}
	}
`;

export const GET_MEDIA_DETAILS = gql`
	query getMediaDetails($ids: [String]!) {
		mediaDetails(ids: $ids) {
			public_id
			secure_url
			filename
			width
			height
		}
	}
`;

export const SAMPLE_FIELDS = gql`
	fragment SampleFields on SampleInfo {
		sampleId
		department
		barcode
		styleNumber
		season
		collection
		designer
		sampleType
		gender
		color
		pattern
		fabric
		description
		manufacturer
		size
		sizeRange
		region
		looks
		misc
		vendor
		vendorDate
		vendorCode
		sampleMadeIn
		image {
			url
			name
		}
		retailPrices {
			currency
			value
		}
		salePrices {
			currency
			value
		}
		customsPrices {
			currency
			value
		}
		extraPrices {
			currency
			value
		}
	}
`;

export const MEDIA_PLACEMENT_FRAGMENT = gql`
	fragment MediaPlacementFragment on MediaPlacement {
		id
		databackendId
		date
		source
		sourceName
		articleLink
		countryId
		countryName
		continentName
		languageCode
		languageName
		imagesCount
		source
		shortDescription
		miv
		potentialReach
		image
		placementType
		engagement
		socialName
		socialAccountLink
		socialViews
	}
`;

export const MERGED_MEDIA_DETAIL_FRAGMENT = gql`
	fragment MergedMediaDetailInfo on MergedMediaDetails {
		id
		ids
		seasonYear
		createdAt
		tags {
			id
			name
			type
			key
			isCustom
		}
		tagRelationships {
			ids
			target {
				...MediaTagFragment
			}
			relations {
				...MediaTagFragment
			}
			isSampleLinked
		}
		showDate
		library {
			id
			name
			industry
		}
		libraryFolders {
			collection {
				name
			}
			album {
				name
			}
			season {
				name
			}
		}
		rootTagsLeaves {
			tag {
				...MediaTagFragment
			}
			leaves {
				...MediaTagFragment
			}
		}
		sampleInfo {
			...SampleFields
		}
		placements {
			...MediaPlacementFragment
		}
		unlinkedSampleMedias
		aiTags {
			...MediaAITagFragment
		}
	}
	${MEDIA_TAG_FRAGMENT}
	${SAMPLE_FIELDS}
	${MEDIA_PLACEMENT_FRAGMENT}
	${MEDIA_AI_TAG_FRAGMENT}
`;

export const GET_MERGED_MEDIA_DETAIL = gql`
	query getMergedMediaDetail($mediaIds: [String]!) {
		mergedMediaDetails(ids: $mediaIds) {
			...MergedMediaDetailInfo
		}
	}
	${MERGED_MEDIA_DETAIL_FRAGMENT}
`;

export const GET_MERGED_MEDIA_DETAILS_ONE_BY_ONE = gql`
	query getMergedMediaDetailsOneByOne($mediaIds: [String]!) {
		mergedMediaDetailsOneByOne(ids: $mediaIds) {
			...MergedMediaDetailInfo
		}
	}
	${MERGED_MEDIA_DETAIL_FRAGMENT}
`;
